<template>
    <div id="applications" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>

                    <!-- end:: header -->
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">
                                    {{ $t("app_applications") }}
                                </h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("app_applicationsSubTitle") }}</span>
                            </div>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'newapplication' }" id="applications_createNewApplicationButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        {{ $t("app_createNewApplication") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div v-if="this.releasedFor == 'ALE'" class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="flaticon-questions-circular-button kt-font-brand"></i></div>
                                    <div class="alert-text">
                                        {{ $t("common_visitApiDoc") }} <a class="kt-link kt-font-bold" :href="docUrl" target="_blank">{{ $t("common_documentation") }}</a>, <a class="kt-link kt-font-bold" rel="noopener" href="/doc/omniaccess-stellar-asset-tracking-developer-center-API_Developer-agreement-rev01.pdf" target="_blank">{{ $t("common_developerAgreement") }}</a> {{ $t("common_and") }} <a rel="noopener" class="kt-link kt-font-bold" href="/doc/omniaccess-stellar-asset-tracking-API_Services-agreement-for-Developer-rev01.pdf" target="_blank">{{ $t("common_servicesAgreement") }}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            />
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) " x="11" y="4" width="2" height="16" rx="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("app_applicationsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar" >
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByNameOrTarget')" @keyup="onSearch" v-model="searchedValue" id="applications_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span>
                                                                            <i class="la la-search"></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="APPLICATION" mainTextKey="app_notFound" subTextKey="app_notFoundSubLabel" imgName="empty_application.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="applications_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            applicationsTable: null,
            applicationsData: null,
            isEmptyList: false,
            searchedValue: "",
            docUrl: process.env.VUE_APP_API_DOC_URL
        };
    },
    created: function() {
        console.log("Component(Applications)::created() - called");
        if (this.createAnotherApplication) {
            this.$router.push({ name: "newapplication" });
        } else {
            // Load user applications from backend and store the result in applications store.
            this.getUserApplications();
        }
    },
    mounted: function() {
        console.log("Component(Applications)::mounted() - called");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Applications)::destroyed() - called");
        this.resetApplicationsState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        user: function(user) {
            console.log("Component(ApplicationsTable)::watch(user) called with :", user);
            if (user) {
                // To take into account user language change
                this.applicationsData = this.buildDatatableData();
                this.initKtDataTable(this.applicationsData);
            }
        },

        userApplications: function(userApplis) {
            console.log(`Component(ApplicationsTable)::watch(userApplications) called`, userApplis);
            if (userApplis) {
                this.applicationsData = this.buildDatatableData();
                this.initKtDataTable(this.applicationsData);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user", "userApplications", "createAnotherApplication"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getUserApplications", "showDeleteModal", "resetApplicationsState"]),

        onSearch() {
            if (this.applicationsTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // To search EITHER by name OR by target:
                // 1. Search in "name" field of applications data
                const dataFoundByName = _.filter(this.applicationsData, app => app && app.name && app.name.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "target" field of applications data
                const dataFoundByMacAddress = _.filter(this.applicationsData, app => app && app.target && app.target.toUpperCase().includes(searchedValueUpperCase));
                // 3. Create an array with previous unique resulting searched
                const dataFound = _.unionBy(dataFoundByName, dataFoundByMacAddress, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.applicationsTable.getPageSize());
                } else {
                    // No searchedValue found => Display empty datatable
                    this.applicationsTable.search(this.searchedValue, "unknown");
                }
            }
        },

        onEditApplication: function(appId) {
            // User clicks on Edit application button in table
            this.$router.push({ name: "editapplication", params: { appId: appId } });
        },

        registerEventsOnApplicationsTable() {
            var self = this;
            $("#applications_datatable").on("kt-datatable--on-layout-updated", function() {
                $(".deleteApplicationButton")
                    .off()
                    .on("click", function() {
                        let appId = $(this).attr("data-appid");
                        let appName = $(this).attr("data-appname");
                        console.log("Component(ApplicationsTable)::registerEventsOnApplicationsTable(deleteApplicationButton) clicked. AppId = " + appId);
                        self.showDeleteModal({ textContent: i18n.t("app_deleteApplicationConfirmText", { appName: appName }), actionName: "DELETE_APPLICATION", params: { appId: appId } });
                    });

                $(".editApplicationButton")
                    .off()
                    .on("click", function() {
                        let appId = $(this).attr("data-appid");
                        console.log("Component(ApplicationsTable)::registerEventsOnApplicationsTable(editApplicationButton) clicked. AppId = " + appId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditApplication(appId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        buildDatatableData() {
            // For search some string which must give the translate string on source data not key
            let dataSource = [];
            if (this.userApplications) {
                for (let appli of this.userApplications) {
                    dataSource.push({
                        id: appli.id,
                        name: appli.name,
                        target: appli.target,
                        updatedAt: appli.updatedAt,
                        state: appli.state,
                        translateState: (appli.state === "DEPLOYED" ? i18n.t("app_deployed") : i18n.t("app_notDeployed"))
                    });
                };
            }
            return dataSource;
        },

        initKtDataTable(dataSource, pageSize) {

            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(dataSource),
                    pageSize: (pageSize ? pageSize : 5)
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("app_applicationName"),
                        textAlign: "center",
                        template: function(row) {
                            return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editApplicationButton kt-user-card-v2__name" data-appid="' + row.id + '" href="javascript:;">' + row.name + "</a>\
                                    </div>\
                                </div>\
                            ";
                        }
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        sortable: "desc",
                        textAlign: "center",
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                            }
                        }
                    },
                    {
                        field: "target",
                        title: i18n.t("app_applicationTarget"),
                        textAlign: "center"
                    },
                    {
                        field: "translateState",
                        title: i18n.t("app_applicationState"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.state == "DEPLOYED") {
                                return '<span class="btn btn-bold btn-sm btn-font-sm btn-label-success">'+row.translateState+'</span>';
                            } else {
                                return '<span class="btn btn-bold btn-sm btn-font-sm btn-label-brand">'+row.translateState+'</span>';
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            return (
                                `
                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_edit") +
                                `" type="button" data-appid="` +
                                row.id +
                                `" class="btn btn-outline-brand btn-sm btn-icon editApplicationButton">
                                    <i class="la la-pencil"></i>
                                </button>
                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button" data-appid="` +
                                row.id +
                                `" data-appname="` +
                                row.name +
                                `" class="btn btn-outline-danger btn-sm btn-icon deleteApplicationButton">
                                    <i class="la la-trash"></i>
                                </button>
                            `
                            );
                        }
                    }
                ]
            };
            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.applicationsTable) {
                $("#applications_datatable").KTDatatable().destroy();
                this.applicationsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.applicationsTable = $("#applications_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnApplicationsTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
            }

        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
